import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  List,
  ListItem,
  ListIcon,
  UnorderedList,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
  useDisclosure,
  VStack,
  Box,
  ModalFooter
} from '@chakra-ui/react'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'
import React from 'react'
import { useSDK } from '../../hooks/useData.js'
const InstallDialog = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const renderingHost = useSDK('renderingHost')
  return (
    <>
      <Button variant={'primary'} onClick={onOpen} mb={6}>
        Install
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Installation instructions</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack>
              <Box fontSize={14} fontWeight={400}>
                <Text mb={6}>
                  In order to install Sitecore Search for host <b>{renderingHost.url}</b> the following steps need to
                  take place:
                </Text>
                <UnorderedList ml={6} fontWeight={600} mb={6}>
                  <ListItem> JSS version is at least X</ListItem>
                  <ListItem> @sitecore/components package version is at least X</ListItem>{' '}
                  <li>Components needs to be imported</li> <ListItem>Search SDK needs to be enabled</ListItem>{' '}
                </UnorderedList>
                <Text mb={3}>In byoc/index.hybrid.tsx file, ensure you have</Text>
                <SyntaxHighlighter
                  id='plugin-install-code'
                  language={'jsx'}
                  style={dark}
                  customStyle={{
                    borderRadius: '4px',
                    fontSize: '14px',
                    flexShrink: '1',
                    overflow: 'auto',
                    margin: '0'
                  }}
                >
                  import '@sitecore/components/search/input'
                </SyntaxHighlighter>
                <Text mt={3}>For further assistance contact your developer</Text>
              </Box>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant={'primary'} onClick={onClose}>
              Done
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

export default InstallDialog
