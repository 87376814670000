import {
  Box,
  Button,
  Card,
  CardBody,
  Container,
  Flex,
  HStack,
  Image,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useSDK } from '../../hooks/useData.js'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
import InstallDialog from './InstallDialog.js'
import UninstallDialog from './UninstallDialog.js'
import PluginPageOverview from './PluginPageOverview.js'
import PluginPageVersions from './PluginPageVersions.js'

const PluginPage = ({ component }: { component: ExternalComponentModel }) => {
  const registeredComponents = useSDK('renderingHost.registeredComponents')
  const isInstalled = !!registeredComponents.find((c) => c.name === component.name)
  const publisher = component.meta?.publisher || component.name.startsWith('Sitecore') ? 'Sitecore' : null

  return (
    <Flex justifyContent={'space-between'}>
      <Box flexGrow={1} mr={6}>
        <Box>
          <Card variant={'outline'} borderColor={'gray.100'} mb={6}>
            <CardBody>
              <HStack>
                {component.links?.icon && <Image src={component.links?.icon} width={'60px'} mr={6} />}
                <Box>
                  <Text fontWeight={600} fontSize={18} mb={4}>
                    {component.title}
                  </Text>
                  <Text>{component.schema.description}</Text>
                </Box>
              </HStack>
            </CardBody>
          </Card>
          <Tabs variant={'line'}>
            <TabList>
              <Tab>Overview</Tab>
              {component.links?.npm && <Tab>Versions</Tab>}
            </TabList>
            <TabPanels>
              <TabPanel>
                <PluginPageOverview component={component} />
              </TabPanel>
              {component.links?.npm && (
                <TabPanel>
                  <PluginPageVersions component={component} />{' '}
                </TabPanel>
              )}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      <Box minWidth={'300px'}>
        <Card variant={'outline'} borderColor={'gray.100'}>
          <CardBody>
            <VStack align={'left'} spacing={2}>
              {isInstalled ? <UninstallDialog /> : <InstallDialog />}
              {component.meta?.version && (
                <Box mb={4}>
                  <>
                    <Text fontWeight={500} fontSize={13}>
                      Version
                    </Text>
                    <Text fontWeight={400} fontSize={14}>
                      {component.meta?.version}
                    </Text>
                  </>
                </Box>
              )}
              {publisher && (
                <Box mb={4}>
                  <Text fontWeight={500} fontSize={13}>
                    Published by
                  </Text>
                  <Text fontWeight={400} fontSize={14}>
                    {publisher}
                  </Text>
                </Box>
              )}
              <Box mb={4}>
                <Text fontWeight={500} fontSize={13}>
                  Category
                </Text>
                <Text fontWeight={400} fontSize={14}>
                  {component.group}
                </Text>
              </Box>
              {component.links?.github && (
                <Box mb={4}>
                  <>
                    <Text fontWeight={500} fontSize={13}>
                      Support
                    </Text>
                    <a href={component.links?.github}>Repository</a>
                  </>
                </Box>
              )}
            </VStack>
          </CardBody>
        </Card>
      </Box>
    </Flex>
  )
}

export default PluginPage
