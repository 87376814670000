import ReactShadowRoot from 'react-shadow-root'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Box } from '@chakra-ui/react'
import React, { useContext, useEffect, useState } from 'react'
import { getGithubRawReadmeUrl } from '../../utils/url.js'
import { useSDK } from '../../hooks/useData.js'
// @ts-ignore
import SyntaxHighlighter from 'react-syntax-highlighter/dist/cjs/prism-light.js'
// @ts-ignore
import dark from 'react-syntax-highlighter/dist/cjs/styles/prism/material-oceanic.js'
import { ExternalComponentModel } from '@sitecore-feaas/sdk'
import { EnvironmentContext } from '../providers/EnvironmentProvider.js'
const PluginPageOverview = ({ component }: { component: ExternalComponentModel }) => {
  const sdk = useSDK()
  const [overview, setOverview] = useState('')
  const env = useContext(EnvironmentContext)

  useEffect(() => {
    const url = getGithubRawReadmeUrl(component.links?.github)
    // TODO: We need to change that we have to find another way or another token
    const isSitecoreComponent = component.name.startsWith('Sitecore')
    const sitecoreAuth = { Authorization: `token ${env.pluginsRepoToken}` }
    if (url) {
      setOverview('Loading overview...')
      sdk
        .proxy(url, { method: 'GET', ...(isSitecoreComponent ? sitecoreAuth : {}) })
        .then((response) => response.text())
        .then((readme) => {
          setOverview(readme)
        })
    }
  }, [])
  return (
    <Box className={'plugin-page-overview'}>
      {/*@ts-ignore TS 4.9 FIXME*/}
      <ReactShadowRoot>
        <ReactMarkdown
          remarkPlugins={[remarkGfm]}
          components={{
            code(props) {
              const { children, className, node, ...rest } = props
              const match = /language-(\w+)/.exec(className || '')
              return match ? (
                <SyntaxHighlighter
                  {...rest}
                  PreTag='div'
                  children={String(children).replace(/\n$/, '')}
                  language={match[1]}
                  style={dark}
                />
              ) : (
                <code {...rest} className={className}>
                  {children}
                </code>
              )
            }
          }}
        >
          {overview || 'No overview information found'}
        </ReactMarkdown>
      </ReactShadowRoot>
    </Box>
  )
}

export default PluginPageOverview
